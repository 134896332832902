.services-list-card-grid-container {
	@extend .is-variable;
	@extend .is-8-desktop;
}

.services-list {
	text-align: center;
	padding-top: 47px;
	padding-bottom: 32px;

	@include tablet {
		padding-top: 80px;
		padding-bottom: 70px;
	}

	@include desktop {
		padding-top: 113px;
		padding-bottom: 109px;
	}

	p {
		margin-left: auto;
		margin-right: auto;
	}
	
	h2, h3, p {
		@include sectionPadding-horz;
	}

	.services-list-title {
		@extend %h1;
		color: $neutral-dk;
		margin-bottom: 62px;
		@include tablet {
			margin-bottom: 55px;
		}
	}

	.services-list-description {
		display: none;
		max-width: 36em;
		margin-bottom: 82px;
		@include tablet {
			display: block;
		}
	}

	.services-list-card {
		@include tablet {
			margin-bottom: 53px;
			display: flex;
			flex-direction: column;
			&:nth-of-type(2n){
				.services-list-icon {
					color: $secondary;
				}
			}
		}
		@include desktop {
			&:nth-of-type(2n){
				.services-list-icon {
					color: $primary-color-lighter;
				}
			}
			&:nth-of-type(3n + 2){
				.services-list-icon {
					color: $secondary;
				}
			}
		}

	}
	.slick-slide:nth-of-type(2n){
		.services-list-icon {
			color: $secondary;
		}
	}

	.services-list-card-title {
		@extend %h2;
		text-transform: none;
		color: $neutral-dk;
		margin-bottom: 18px;
		line-height: 1.15;
	}

	.services-list-card-body {
		margin-bottom: 17px;
		max-width: 20em;
		@extend %body-2;
	}

	.services-list-card-cta {
		font-weight: 700;
		@include tablet {
			margin-top: auto;
		}
	}

	.services-list-icon {
		font-size: 64px;
		display: block;
		margin-bottom: 30px;
		color: $primary-color-lighter;
	}

	.services-list-cta {
		@extend %button-secondary;
		margin-top: 43px;
		display: none;
		margin-left: auto;
		margin-right: auto;
		padding-left: 40px;
		padding-right: 40px;
		@include tablet {
			display: inline-block;
		}
	}
	
	.slick-arrow {
		top: 30px;
		transform: none;
		z-index: 100;
		&::before{
		  font-family: "pathway-icons";
		  font-size: 25px;
		  color: $neutral-dk;
		}
		&.slick-prev {
		  left: 40px;
		  &::before {
			content: "\e875";
		  }
		}
		&.slick-next {
		  right: 40px;
		  &::before {
			content: "\e876";
		  }
		}
	  }

	  .services-accordion-button {
		  min-width: 230px;
	  }

}
