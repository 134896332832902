.referring-vets {
    padding-top: 60px;
    padding-bottom: 84px;
    margin-bottom: 0;
    position: relative;

    @include tablet {
        padding-top: 106px;
        padding-bottom: 72px;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        background-color: $primary-color-light;
        z-index: -10;
        @include tablet {
            @include backgroundSvg;
            height: 34%;
            background-color: transparent;
            background-size: 229px 125px;
            bottom: 20%;
            background-position: -100px 0;
        }
    }
    .referring-vets-section-title {
        @extend %h1;
        margin-bottom: 48px;
        @include tablet {
            margin-bottom: 113px;
        }
    }
    .referring-vets-item {
        padding-bottom: 48px;
        background-color: $white;
        border-width: 4px;
        border-radius: 7px;
        border-color: $neutral-dk;
    }
    .referring-vets-title {
        @extend %subhead;
        color: $neutral-dk; 
    }
    .referring-vets-link {
        @extend %button-link;
    }

    .slick-arrow {
        display: none!important;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}