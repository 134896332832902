.one-col-promo {
	position: relative;
	@include maximize-width;
	border: none;
	background-size: 100%;
	background-repeat: no-repeat;
	padding: 0;
	background-color: $white;
	margin: 0 auto;

	@include until( $desktop ){
		box-sizing: content-box;
	}

	@include desktop {
		padding: 115px 3% 115px 0;
		background-size: cover; 
	} 

	.one-col-promo-bg {
		width: 100%;
		height: 100%;

		img {
			display: block;
		}

		@include desktop {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;

			// img {

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: 50% 0;
					@include object-fit( cover, 50% 0 );
				}
				
			// }
		}
	}

	.one-col-promo-container {
		@extend .container;

		@include desktop {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
	
		}
	}

	.one-col-promo-content {
		@include desktop {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
	

	.one-col-promo-image {
		position: relative;
		@include bg-dot;
		z-index: 100;
		width: 100%;
		margin-top: 30px;
		margin-bottom: -110px;

		@include tablet {
			margin-top: 50px;
		}

		@include desktop {
			width: 40%;
		}

		.p-icon {
			color: $neutral-dk;
			display: block;
			font-size: 60px;
			width: 60px;
			margin-left: auto;
			margin-right: auto;
			transform: translateX(-10px);
			z-index: 10;

			@include tablet {
				font-size: 80px;
				width: 80px;
			}

			@include desktop {
				width: 96px;
			}
		}

		&::after {
			width: 60px;
			height: 60px;
			right: 0;
			left: 50%;
			transform: translateX( -50% );
			z-index: -1;

			@include desktop {
				width: 70px;
				height: 70px;
				top: -30px;
			}
		}
	}

	.one-col-promo-text{
		background: $white;
		text-align: center;
		border: none;
		position: relative;
		padding: 90px 0 10px;
		margin: 40px;
		// padding-top: 90px;
		

		@include desktop {
			width: 40%;
			padding: 127px 80px 80px 80px;
			margin: 0;
		}

		@include widescreen {
			padding-top: 164px;
		}

		&::before {
			content: "";
			width: 200%;
			height: 100%;
			position: absolute;
			border-style: solid;
			border-width: 4px;
			border-color: $neutral-dk;
			left: -50%;
			top: 0;
			@include desktop {
				width: 100%;
				width: calc(100% - 60px);
				height: calc(100% - 60px);
				top: 30px;
				left: 30px;
			}
		}
	}

	.one-col-promo-title {
		@extend %h2;
		color: $neutral-dk;
		text-transform: none;
		margin-bottom: 35px;
		@include desktop {
			margin-bottom: 25px;
		}
	}

	.one-col-promo-body {
		margin-bottom: 35px;
		@include until($desktop){
			max-width: 28em;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.one-col-promo-cta {
		@extend %button-primary;
		@include buttonWidth;
		position: relative;
		margin-top: 0;
		top: 2em;
		z-index: 10;
		box-shadow: 0 0 0 6px $white;
		@include desktop {
			margin-bottom: 30px;
		}
	}

}
