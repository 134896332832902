.events-grid-columns {
	@extend .is-6-desktop;
	@extend .is-8-widescreen;
}

.events-grid {
    padding: 0;
    @include tablet {
        padding-top: 84px;
    }

    .event-item {
        @include tablet {
            margin-bottom: 40px;
        }
    }

    .event-thumbnail {
        margin-bottom: 46px;
        img {
            width: 100%;
        }
    }
    
    .event-text {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        @include desktop {
            padding: 0;
        }
     }

     .event-date, .event-add-calendar, .event-content {
         flex: 1 0 100%;
         @include until($tablet) {
             text-align: center;
         }
     }

    .event-title {
        @extend %subhead;
        margin-bottom: 46px;
    }

    .event-date {
        @extend %body-1;
        text-transform: none;
        font-size: 20px;
        line-height: 1.05;
        margin-bottom: 20px;
    }

    .event-add-calendar {
        font-size: 16px;
    }

    .event-link {
        color: $black;
        text-decoration: underline;
        position: relative;
        top: .25em;
        &:hover {
            color: $secondary;
        }
    }

    .event-add-calendar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: -25px;
        margin-right: -25px;
        margin-bottom: 46px;
        .event-calendar-li {
            padding: 0 25px;
            line-height: 1;
            font-weight: 700;
            letter-spacing: .07em;
            &:nth-last-of-type(n + 2){
                border-right-style: solid;
                border-right-width: 1px;
                border-right-color: $black;
            }
        }
        @include tablet {
            justify-content: flex-start;
            margin-left: -12px;
            margin-right: -12px;
            .event-calendar-li {
                padding: 0 12px;
            }
        }
    }

    .event-body {
        @extend %body-2;
    }


}

// .communityEvents {
//     padding: 80px;
//     &-event {
//         flex-direction: row;
//         align-items: center;
//         margin-bottom: 30px;
//     }
//     .is-content-half {
//         padding: 60px 40px;
//     }
//     h3.event-title {
//         margin-bottom: 40px;
//     }
//     p.event-content {
//         margin: 10px 0 30px;
//     }
//     div.level {
//        display: flex;
//        flex-direction: column;
//         align-items: flex-start;
//         .p-icon.icon-chevron-down {
//             margin-left: 10px;
//         }
//     }
//     .image-container {
//         width: 80%;
//         height: auto;
//         position: relative;
//         img { 
//             width: 100%;
//             height: auto;
//         }
//         &::after {
//             content: "";
//             position: absolute;
//             height: 8px;
//             background: #F2835B;
//             left: 0;
//             width: 100%;
//             top: 0;
//             z-index: 1;
//         }
//     }
//     @media screen and (min-width: $tablet) {
//         div.level {
//             width: 90%;
//            flex-direction: row;
//             .p-icon.icon-chevron-down {
//                 margin-left: 10px;
//             }
//         }
//     }
// }