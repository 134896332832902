.team-cards {
	margin-bottom: 0;

	h2.team-cards-title {
		@extend %h1;
		margin-bottom: 50px;
		text-transform: none;
		@include tablet {
			margin-bottom: 90px;
		}
	}

	.team-card {
		text-align: left;
	}

	.team-photo {
		border-radius: 0;
		width: 272px;
		height: 272px;
		margin-bottom: 30px;
	}

	   .team-card {
			h3, h4, p, a,
			.team-description {
				width: 272px;
				margin-left: auto;
				margin-right: auto;
				padding: 0; 
			}
	   }
   

   .team-card {
		.team-name {
			border-top-style: solid;
			border-top-width: 4px;
			border-top-color: $neutral-dk;
			padding-top: 43px;
		}
		.team-title {
			font-size: 20px;
		}
		.team-description-short {
			@extend %body-2;
		}
		.modal-slide-button {
			margin-top: 32px;
		}
   }

	.slick-dots {
		& li.slick-active button::before {
			top: -1px;
		}
	}
	
}

.leadership-card.column.is-6-tablet {
	@extend .is-12-tablet;
}

.leadership-cards {
	
	margin-bottom: 0;

	h2.leadership-cards-title {
		@extend %h1;
		margin-bottom: 58px;
		text-transform: none;
		@include until($tablet) {
			font-size: 30px;
		}
		@include tablet {
			margin-bottom: 98px;
		}
	}

	.card-grid-container.leadership-card-grid-container {
		justify-content: center;
	}

	.leadership-card {
		border-style: solid;
		border-width: 4px;
		border-color: $neutral-dk; 
		border-radius: 7px;
		background-color: $white;
		padding: 0;
		text-align: left;
		
		h3, h4, p, a.link,
		.leadership-description {
			padding-left: 16px;
			padding-right: 16px;
		}

		@include until($tablet){
			width: 289px!important;
		}

		.leadership-photo {
			width: 100%;
			margin-bottom: 17px;
		}

	}

	.leadership-text {
		margin-bottom: 20px;
	}

	.leadership-title {
		text-transform: none;
		font-size: 20px;
		letter-spacing: .07em;
	}

	.leadership-photo {
		// height: 100%;

		img {
			display: block;
			object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
			width: 100%;
			height: 100%;
		}
	}

	.link {
		margin-bottom: 28px;
	}

	.slick-dots {
		& li.slick-active button::before {
			top: -1px;
		}
	}

	@include tablet { 
		.card-grid-container {
			justify-content: center;
		}
		.leadership-card {
			max-width: 750px;
			margin-bottom: 72px;
		}

		.leadership-card-content {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			.leadership-photo {
				width: 47%;
				height: auto;
				flex: 0 0 47%;
				margin: 0;
			}
	
			.leadership-text {
				flex: 0 0 50%;
			}
	
			.leadership-name {
				margin-top: 42px;
				margin-bottom: 12px;
			}
	
			.leadership-title {
				margin-bottom: 30px;
			}
	
			.leadership-description {
				margin-bottom: 12px;
			}
	
			a.link.modal-slide-button {
				padding-top: 0;
				margin-bottom: 32px;
			}
		}

	}

}

.team-cards, .leadership-cards {

    .link {
        border-bottom: none;
    }
	
	.modal-slide {
		h3 {
			margin-bottom: 12px;
		}

		h4 {
			margin-bottom: 36px;
		}

		@include tablet {
			padding-left: 100px;
			padding-right: 100px;

			p {
				text-align: left;
			}
		}
	}

	.card-grid-container { 
		.slick-arrow {
			display: none !important;
		}
    }
    
    .group-name {
        text-align: center;
        color: $neutral-dk;
    }

}