.resource-links {
	.resource-links-container {
		max-width: 1152px;
	}
	.resource-links-title {
		@extend %h1;
		margin-bottom: 42px;
	}

	.resource-links-description {
		max-width: 34em;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 36px;
		@include tablet {
			margin-bottom: 84px;
		}
	}

	.resource-links-list {
		@include until($widescreen){
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.resource-links-li {
		height: auto;
	}

	.resource-links-li a {
		color: $neutral-dk;
		background-color: $white;
		padding: 28px 10px 28px 60px;
		line-height: 1.2;
		border-radius: 7px;
		display: inline-flex;
		align-items: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
		position: relative;
		font-weight: 700;

		&:hover {
			color: $secondary;
		}

		@include until( $tablet ){
			max-width: 500px;
		}

		@include tablet {
			width: 100%;
			padding: 20px 20px 20px 20px;
			height: 100%;
			max-width: none;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1 1 auto;
		}




		.p-icon {
			text-decoration: none;
			display: block;
			position: relative;
			font-size: 250%;
			// left: 20px; 
			margin-right: 20px;
			// top: 50%;
			// transform: translateY(-50%);
			@include tablet {
				position: relative;
				left: 0;
				flex: 0 1 15%;
				margin-right: 5%;
			}
		}

		span {
			@extend %link;
			border-color: currentColor;
		}

		span::after {
			top: 0;
			// text-decoration: underline;
			letter-spacing: 1;
			// font-family: "pathway-icons";
			// content: "\2002\e876";
			// font-size: 70%;
			// display: inline;
			// position: relative;

			@include tablet {
				flex: 0 1 30px;
			}
		}


	}
}