.contact-map {
	border-top-style: solid;
	border-top-width: 2px;
	border-top-color: $neutral-dk;

	.contact-map-container {
		overflow: visible;
	}

	.contact-map-details-column {
		@include tablet {
			justify-content: flex-end;
		}
    }

    .contact-map-details-clinic-name {
        @include until ($tablet){
            font-size: 18px;
        }
    }

	.contact-map-details {
		border-top: none;
		justify-content: center;

		.contact-map-details-group {
			text-align: center!important;
		}

		@include tablet {
			border-right-style: solid;
			border-right-width: 2px;
			border-right-color: $neutral-dk;
			max-width: 700px;
			.contact-map-details-group > div::before {
				content: none;
			}
		}

		@include desktop {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.contact-map-details-title {
		margin-top: 26px;
		margin-bottom: 6px;
		@extend %h1;
	}

	.contact-map-details-clinic-name {
		text-transform: none;
		font-weight: 700;
	}

	.contact-map-details-clinic-name, .contact-map-details-group > div {
		margin-bottom: 20px;

		@include tablet {
			margin-bottom: 46px;
		}
	}

	.contact-map-details-group {
		display: block;
		width: 100%;

		p, h4 {
			// font-weight: 700;
			font-size: 14px;
		}

		@include desktop {
			text-align: center;

			p, h4 {
				font-size: 16px;
			}
		}

	}

	.contact-map-details-phone-emergency {
		padding: .56em $side-padding;
		background-color: $primary-color-light;
		position: relative;
		transition: none;
		margin-left: -$side-padding;
		margin-right: -$side-padding; 

		@include desktop {
			// margin-left: 0;
			// margin-right: 0;
		}

		&::after {
			content: '';
			position: absolute;
			width: 9999px;
			height: 100%;
			right: 100%;
			top: 0;
			background-color: $primary-color-light;
		}
		&:hover {
			transition: none;
			background-color: $primary-color-lighter;
			&::after {
				background-color: $primary-color-lighter;

			}
		}
		h4 {
			color: $black;
			font-weight: 700;
			a {
				color: $black;
			}
		}
	}

	.contact-map-details-phone-general{
		a {
			color: $black;
			font-weight: 700;
		}
	}

	.contact-map-details-location-title, .contact-map-details-hours-title {
		color: $black;
		font-weight: 700;
	}

	.contact-map-details-email {
		a {
			text-decoration: underline;
		}
	}

		#g-map {
			width: 100%;
			height: 300px;
			@media (min-width: $tablet) {
			height: 700px;
			}
        }
        
        &.multisite {

					.contact-map-details {
						.contact-map-details-phone-emergency,
						.contact-map-details-phone-general,
						.contact-map-details-email,
						.contact-map-details-location,
						.contact-map-details-hours {
								&:before {
										content: none;
								}
						}
		
				}

            .contact-map-details-group {
                h4 {
                    font-weight: bold;
                }
            }
    
            .contact-map-details-group {
                .contact-map-details-email,
                .contact-map-details-hours,
                h4 {
                    margin-bottom: .6em;
                }
            }

            .contact-map-details-column {
                @include tablet-only {
                    justify-content: center;
                    
                    .contact-map-details {
                        border-right: none;
                    }
                }
            }
            .contact-map-details-phone-general a,
            .contact-map-details-phone-emergency a{
                font-weight: normal!important;
            }
    
            .multisite-location-social-list {
                justify-content: center;
            }
        }

}
