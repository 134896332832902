.hero-carousel {
	.hero-carousel-cta {
		@extend %button-link;
	}
}

.hero-carousel-home.hero-carousel {
	position: relative;
	border-bottom-style: solid;
	border-bottom-width: 4px;
	border-bottom-color: $primary-color;

	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 30%;
		bottom: 0;
		left: 0;
		@include backgroundSvg;
		background-size: 144px 81px;
		background-repeat: repeat;
		background-position: 50% 0;
		z-index: -10;
	}

	@include tablet {
		padding-top: 10px;
		&::after {
			height: 248px;
			background-size: 288px 162px;
		}
	}

	.pathway-carousel {
		margin-bottom: 0;
	}

	.hero-carousel-card {
		padding: 0;
		width: 100%;
		display: flex!important;
		justify-content: flex-end;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		@include tablet {
			flex-direction: row;
		}
		@include until( $tablet ){
			
		}
	}

	.hero-carousel-text {
		text-align: center;
		padding: 20px 25px 0;
		width: 100%;
		@include tablet {
			flex: 0 1 54%;
			align-self: center;
			text-align: left;
			padding-left: 80px;
		}

		@include until( $tablet ){
			margin: auto;
		}
	}

	.hero-carousel-title {
		@extend %hero-2;
		margin-bottom: 60px;
		@include desktop {
			font-size: 45px;
		}
		@include widescreen {
			font-size: 65px;
		}
	}

	.hero-carousel-cta {
		margin-bottom: 32px;
		padding-left: 20px;
		padding-right: 20px;
		font-size: 18px;

		@include desktop {
			font-size: 21px;
			padding: 14px 36px;
		}
	}

	.hero-carousel-image-wrap {
		img {
			display: block;
			height: 448px;
			width: auto;
			margin: 0 auto;
			max-width: none;
			padding: 0;
		}
		@include tablet {
			flex: 0 1 44%;
			align-self: flex-end;
			padding-right: 80px;
			img{
				width: 100%;
				height: auto;
			}
		}
		@include until( $tablet ){
			margin-top: auto;
			// max-width: 100%;
			img {
				max-width: 110%;
				max-height: 440px;
				width: auto;
				height: auto;
			}
		}
	}

	.slick-track {
		// @include tablet {
			display: flex;
			.slick-slide {
				height: auto;
				> div {
					height: 100%;
				}
			}

		// }
	}

	.slick-slide {
		overflow: hidden;
		@extend .container;
	}

	.slick-dots {
		bottom: 10px;
		background: none;
		display: none!important;
	}

	.slick-arrow {
		bottom: 50%;
		display: none!important;
	}

	.slick-arrow, .slick-dots {
		@include tablet {
			display: block!important;
		}
	}
} 

.hero-carousel-alt.hero-carousel {

	.hero-carousel-title {
		@extend %hero-2;
	}

	.slick-dots li button:before {
		content: '';
		width: 18px; 
		height: 18px;
		border-radius: 50%;
		border: solid 1px;
		border-color: $white;
		opacity: .8;
		background: transparent;
		top: 0;
		left: 0;
	}

	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.slick-dots li.slick-active {
		top: 0;
	}
	
	.slick-dots li.slick-active button:before {
		background: $white;
		opacity: 1;
	}


	@include until( $tablet ){

		.hero-carousel-wrap {
			background: $primary-color;
			padding-bottom: 50px;
			position: relative;
		}

		.slick-arrow {
			display: none !important;
		}

		.slick-dots {
			background: $primary-color;
			margin: 0;
			bottom: 16px;
		}

		.hero-carousel-card {
			display: flex !important;
			flex-direction: column;
			background: $primary-color;

			.hero-carousel-text {
				order: 2;
				background: $primary-color;
				padding: 30px 20px;
				text-align: center;
			}

			.hero-carousel-title {
				color: $primary-color-invert;
			}

			.hero-carousel-cta {
				margin-top: 40px;
			}
		}
	}

	@include tablet {

		.hero-carousel-card {
			position: relative;
			width: 100%;
			height: 100%;
			min-height: 695px;
			padding: 40px;
			display: flex !important;
			align-items: center;
			justify-content: flex-start;
		}

		.hero-carousel-image-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0; 
			left: 0;
			z-index: -1;

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background: $neutral-dk;
				opacity: .3;
			}
		}

		.hero-carousel-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
		}

		.hero-carousel-text {
			margin-left: 8%;
			width: 80%;
		}

		.hero-carousel-title {
			color: $white;
		}

		.hero-carousel-cta {
			margin-top: 3em;
		}

		.slick-slider .slick-arrow {
			top: 50%;
			transform: translateY( -50% );
			color: $white;

			&.slick-prev {
				left: 20px;
			}
		}
	}

	@include desktop {

		.hero-carousel-text {
			width: 40%;
		}

		.hero-carousel-cta {
			font-size: 21px;
			padding-left: 1.6em;
			padding-right: 1.6em;
		}
	}



}
