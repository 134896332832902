.emergency-group {
  padding-top: 56px;

  @include tablet {
    padding-top: 106px;
    padding-bottom: 70px;
  }

  @include widescreen {
    .columns.is-variable.is-8 {
      --columnGap: 4rem;
    }
  }

  h2 {
    text-align: center;
    @include tablet {
      text-align: center;
    }
  }

  .column-left {
    background: $primary-color-light;
  }

  .emergency-page-image {
    img {
      @include until ($tablet){
        width: 100%;
      }
    }
  }

  .emergency-services,
  .emergency-info {
    padding-left: 24px;
    padding-right: 24px;
    

    @include tablet {
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  .emergency-info {
    padding-top: 52px;
    padding-bottom: 23px; 

    @include tablet {
      padding-top: 80px;
      padding-bottom: 45px;
    }
  }

  .emergency-services {
    padding-top: 23px;
    padding-bottom: 52px;

    @include tablet {
      padding-top: 45px;
      padding-bottom: 80px;
    }
  }

}

.emergency-info {

  .einfo-intro {
    margin-bottom: 15px;
    @include tablet {
      margin-bottom: 30px;
    }

     p {
      text-align: left;
    }

    h2 {
      margin-bottom: 1em;
    }

    p {
      line-height: 1.2;
      font-size: 14px;

      @include tablet {
        font-size: 16px;
      }
    }
  }

  .einfo-item p{
    color: $black;
    font-size: 14px;
    margin-bottom: .5em;

    @include tablet {
      font-size: 16px;
    }

    &:before {
      content: "\2010\2003";
    }
  }

}

.emergency-services {

  @include until( $tablet ){
    .eservices-intro h2,
    .eservices-list {
      text-align: center;
    }
  }

  .eservices-intro h2 {
    margin-bottom: 1.5em;
  }

  .eservices-list li,
  .link {
    font-size: 14px;
    margin-bottom: 1.5em;

    @include tablet {
      font-size: 16px;
    }
  }

}

.emergency-three-col {
  @include mobileSidePadding;
  padding-top: 25px;
  // padding-bottom: 45px;
  // @include tablet {
  //   padding-bottom: 110px;
  // }

  .e3col-intro, .e3col-column {
    margin-bottom: 55px;
  }

  .e3col-intro {

    h2 {
      margin-bottom: 1em;
    }
    p {
      @include until( $tablet ){
        text-align: center;
      }
    }
  }

  .e3col-content,
  .e3col-intro-text {
    p, li {
      text-align: left;
      font-size: 14px;
    }
  }

  .e3col-content {
    h3 {
      color: $secondary;
      margin-bottom: 1em;
      @include until( $tablet ){
        font-size: 20px;
        text-align: center;
      }
    }
    p, li {
      font-size: 16px;
      margin-bottom: 1em;
    }
    li {
      list-style: none;
      padding: 0;
      color: $black;
      // margin-bottom: 1em;
      &::before {
        content: "\2010\2003";
      }
    }
  }
  
}