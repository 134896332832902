.page-header {
    margin-bottom: 0;
    position: relative;
    padding: 0;
    border-bottom-style: solid;
	border-bottom-width: 4px;
    border-bottom-color: $primary-color;
    
    &.page-header-background {
        border-bottom: none;
    }

    .container {
        width: 100%;
        max-width: none!important;
        padding: 32px 0 40px;

        @include tablet {
            padding: 80px 0;
        }

        @include desktop {
            padding: 120px 0;
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 40px;
            bottom: 0;
            z-index: -10;
            @include backgroundSvg;
            background-size: 59px 32px;
            background-repeat: repeat;
            order: 2;
    
            @include tablet {
                height: 80px;
                background-size: 110px 60px;
            }
    
            @include desktop {
                height: 120px;
                background-size: 176px 96px;
            }
        }
    }

    .page-header-image {
        @include maximize-width;
        max-height: 680px;
        overflow: hidden;
        order: 3;
        img {
            width: 100%; 
            height: auto;
        }
    }




   h1.page-title {
       @extend %hero-1;
       padding: 0 32px;
   }
}