.testimonial-grid {
    background-color: $primary-color-lighter;
    background: linear-gradient(180deg, $white 50%, $primary-color-lighter 50%);
    padding: 52px 0 135px;
    text-align: center;
    margin-bottom: 0;

    .testimonial-grid-title {
        @extend %h1;
        margin-bottom: 45px;
        @include tablet {
            margin-bottom: 60px;
        }
    }

    .testimony-blockquote {
        transition: .5s;  
        padding: 0 15px; 
        @include tablet {
			padding: 0;
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
        } 
        blockquote {
            padding: 30px 30px;
            position: relative;
            background-color: $white;
            border-radius: 7px;   
            @include tablet {
                border-radius: 0;
                background-color: $neutral-lt; 
                padding: 125px 60px 60px 60px;
            }
            @include desktop {
                padding: 176px 89px 68px;
            } 
            &::after {
                content: "";
                display: block;
                width: 100%; 
                height: 100%;
                top: 0; left: 0;
                position: absolute;
                border-style: solid;
                border-width: 4px;
                border-color: $neutral-dk;
                border-radius: 7px;
                @include tablet {
                    width: calc(100% - 20px);
                    height: calc(100% - 20px);
                    left: 10px;
                    top: 10px;
                }
                @include desktop {
                    width: calc(100% - 76px);
                    height: calc(100% - 76px);
                    left: 38px;
                    top: 38px;
                }
            }
        }
    }

    .slick-slide {
        width: 292px;
        @include tablet {
            width: 400px;
        }
        @include desktop {
            width: 666px;
        }
    }

    @include tablet {
        .slick-slide:not(.slick-active){
            .testimony-blockquote {
                transform: scale(.8);
                transform-box: cover;
            }  
        }
    }


    .testimonial-image {
        width: 170px;
        height: 170px;
        border-radius: 170px;
        margin:0 auto -85px auto;
        background-size: cover;
        display: none;
        position: relative;
        z-index: 100;
        @include tablet {
            display: block;
        }
        @include desktop {
            width: 204px;
            height: 204px;
            border-radius: 204px;
            margin-bottom: -142px;
        }
    }

    .testimonial-title {
        color: $neutral-dk;
        font-size: 20px;
        @include tablet {
            font-family: "Playfair Display", serif;
            text-transform: none;
        }
        @include desktop {
            font-size: 30px;
        }
    }

    .testimonial-text {
        @extend %body-2;
        margin-bottom: 26px;
    }

    .testimonial-name {
        @extend %body-2;
        color: $secondary;
        font-weight: 700;
        margin-bottom: 0;
        &::before {
            content: " -";
        }
    }

    .slick-arrow {
		@include until( $tablet ){
			display: none!important;
		}
    }

    .slick-dots {
    }

} 