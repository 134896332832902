@mixin sectionPadding-vert {
        padding-top: 50px;
        padding-bottom: 50px;
        @include tablet {
        padding-top: 85px;
        padding-bottom: 85px;
        }
        @include desktop {
          padding-top: 115px;
          padding-bottom: 146px;
        }
}

@mixin sectionPadding-horz {
    @include until($desktop){
        padding-left: 50px;
        padding-right: 50px;
    }
}

@mixin buttonWidth {
  min-width: 200px;
  @include tablet {
    min-width: 232px;
  }
}

@mixin bg-dot {
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 76px;
    height: 76px;
    border-radius: 76px;
    background-color: $secondary;
    top: -20px; 
    right: -20px;
    border-style: solid;
    border-width: 8px;
    border-color: $white;
    box-sizing: content-box;
    background-clip: padding-box;
  }
}

%theme-card  {
  padding: 30px 20px 20px 20px;
  background-color: $white;
  border-style: solid;
  border-width: 4px;
  border-radius: 7px;
  border-color: $neutral-dk;
}

