.photo-gallery {

	.modal-content-slider{
		.photo-gallery-modal-image {
			padding: 10px;
		}
		.photo-gallery-modal-close {
			top: 20px;
			right: 20px;
			left: initial;
		}
	} 
	@include tablet {
		.container, .columns {
			width: 100%!important;
			max-width: none;
			margin-left: 0;
			margin-right: 0;
		}
		.slick-slide {
			width: 720px;
		}
	}
	
	.photo-gallery-image-thumbnail {
		&:hover {
			.photo-gallery-image {
				transform: none;
			}
		}
		@include tablet {
			height: 720px!important;
			width: 720px!important;
			padding: 0;
			margin: 0;
		}
	}
	
	.photo-gallery-container {
		.slick-dots {
			li button:before {
				top: -1px;
			}
		}
	}

	&.image-gallery-text {

		// @include until( $tablet ) {
			.photo-gallery-modal-image {
				padding: 0;

				@include tablet {
					padding-top: 10px;

					.column {
						padding-bottom: 0;
					}
				}
			}
		// }
	}
}