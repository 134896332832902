﻿.three-col-img-columns {
	@extend .is-8;
}

.three-col-with-image {
	@include sectionPadding-vert;
	@include sectionPadding-horz;
	text-align: center;

	.three-col-img-title, .three-col-img-subtitle {
		text-transform: none;
		color: $neutral-dk;
		margin-bottom: 30px;
		@include desktop {
			margin-bottom: 42px;
		}
	}


	.three-col-img-intro {
		@extend %body-1;
		margin-bottom: 50px;
		@include desktop {
			margin-bottom: 138px;
		}
	}

	.three-col-img-title {
		@extend %h1;
	}

	.three-col-img-subtitle {
		@extend %h2;
		line-height: 1.2;
	}

	.three-col-img-column {
		display: flex;
		flex-direction: column;
        align-items: center;
		justify-content: flex-start;
		height: auto;
		
		@include until( $desktop ){
			padding: 40px;
		}
 
		@include desktop {
			flex-direction: column;
            // padding: initial;
		}
	}



	.three-col-img-column-2 .three-col-img-image::after {
		background-color: $secondary-light;
	}


	.three-col-img-content {
		width: 100%;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		@include tablet {
			height: 100%;
		}
	}

	.three-col-img-subtitle {
		width: 100%;
	}

	.three-col-img-body {
		@extend %body-2;
		margin-bottom: 50px;
		color: $black;
		width: 100%;

		p {
			@extend %body-2;
			color: $black;
		}

		@include until($tablet){
			max-width: 420px;
			margin-left: auto;
			margin-right: auto;
		}

		@include tablet {
			flex: 1 1 100%;
		}
		@include desktop {
			margin-bottom: 65px;
		}
	}

	.three-col-img-cta {
		@extend %button-secondary;
		min-width: 200px;
		margin-top: auto;
		@include desktop {
			min-width: 252px;
		}
	}

}

.three-col-img-image {
	font-size: 86px;
	width: 105px;
	height: 86px;
	padding-top: 86px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;

	.p-icon {
		color: $neutral-dk;
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 85px;
	}

	@include bg-dot;

	&::after {
		width: 75px;
		height: 75px;
		border-radius: 75px;
		background-color: $primary-color-light; 
		right: -4px;
		top: -8px;
		z-index: -10;
	}

	@include desktop {
		margin-bottom: 100px;
	}
}
