.social-bar {
	background-color: $neutral-dk;

	.newsletter-signup-nested {
		display: none;
	}

	.social-bar-container .social-icons-nested {
		justify-content: center;
	}
	
}

.social-icons-item {
	margin-left: 8px;
	margin-right: 8px;

	@include tablet {
		margin-left: 15px;
		margin-right: 15px;
	}
		a {
			color: $white;

		&:hover {
			color: $secondary;
		}
	}
}


.footer {

	a, p {
		@extend %footer-text;
	}

	.footer-container {
		@include until( $tablet ){
			width: 100%;
		}
	}

	.footer-links {

		@include until ( $tablet ){
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
		}

		.footer-link {
			text-align: left;
			color: $black;
			&:hover {
				color: $primary-color;
			}
		}
	}

	$button-radius: 7px;

	.newsletter-signup {
		h4 {
			font-size: 16px;
			font-weight: 700;
			color: $secondary;
			text-transform: uppercase;

		}
		.newsletter-signup-text {
			@extend %body-2;
			font-size: 14px;
			margin-bottom: 28px;
		}
		input.newsletter-signup-input {
			box-shadow: none;
			padding: 10px;
			border-top-left-radius: $button-radius;
			border-bottom-left-radius: $button-radius;
		}
		.newsletter-signup-button {
			@extend %button-link;
			border-radius: 0;
			border-top-right-radius: $button-radius;
			border-bottom-right-radius: $button-radius;
			font-size: 0px;
			color: transparent;
			&::after {
				color: $secondary-invert;
				font-size: 16px;
				padding-bottom: calc(0.375em - 1px);
				padding-left: 0.75em;
				padding-right: 0.75em;
				padding-top: calc(0.375em - 1px);
			}
		}
	}
}

.pathway-footer-stamp {
	background-color: $primary-color-light;
	.pathway-footer-stamp-text {
		@extend %body-2;
		font-weight: 700;
	}
	.pathway-footer-stamp-text {
		color: $black;
	}
	.pathway-footer-stamp-logo {
		svg path {
			fill: $black;
		}
	}
}
