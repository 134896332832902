.social-reviews {
	position: relative;
	margin-bottom: 0;
	padding: 70px 0 186px 0; 
	text-align: center;
	@include tablet {
		padding-top: 187px;
		padding-bottom: 128px;
	}
	&::after {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		content: "";
		@include backgroundSvg;
		background-size: 285px 156px;
		background-repeat: repeat-x;
		background-position: bottom left;
		z-index: -1;

		@include tablet {
			background-position: center left;
			background-size: 366px 200px;
		} 
	}

	.social-reviews-content {
		@media ( min-width: 400px ){
			border-color: $neutral-dk;
			border-style: solid;
			border-radius: 8px;
			border-width: 4px;
		}

	}

	.social-reviews-item {
		padding-top: 40px;
	}

	.social-reviews-starbar {
		margin-top: -60px;
		justify-content: center;
	}

	.social-reviews-logo {
		box-shadow: 0 0 0 10px $white;
		background: $white;
	}

	.social-reviews-author {
		font-size: 20px;
	}

	.social-reviews-date {
		// @extend %form-text;
	}

	.social-reviews-body {
		@extend %body-2;
	} 

} 
 
// .social-reviews {
// 	position: relative;
// 	margin-bottom: 0;
// 	@include backgroundSvg;
// 	background-size: auto 156px;
// 	background-repeat: repeat-x;
// 	background-position: bottom left;
// 	padding: 70px 0 186px 0; 
// 	background-color: transparent;
// 	text-align: center;
// 	@include tablet {
// 		background-position: center left;
// 		background-size: auto 200px;
// 		padding-top: 187px;
// 		padding-bottom: 128px;
// 	}
// 	&::after {
// 		content: none;
// 	}

// 	.social-reviews-title {
// 		@extend %h1;
// 		margin-bottom: 1.5em;
// 	}

// 	.social-reviews-image {
// 		display: none!important;
// 	}

// 	.social-reviews-content {
// 		padding-top: 88px;
// 		border-color: $neutral-dk;
// 		background-color: $white;
// 		border-style: solid;
// 		border-radius: 8px;
// 		border-width: 4px;
// 		position: relative;
// 		max-width: 820px;
// 		margin-left: auto;
// 		margin-right: auto;
// 		@include until($tablet){
// 		   border: none;
// 		   &::before {
// 			   content: "";
// 			   position: absolute;
// 			   width: 200%;
// 			   left: -50%;
// 			   top: 0;
// 			   height: 4px;
// 			   background-color: $neutral-dk;
// 		   }
// 		}
// 		@include tablet {
// 			padding: 120px 83px 70px;
// 		}
// 		.social-reviews-title {
// 			color: $neutral-dk;
// 		}
// 		.button {
// 			@extend %button-link;
// 			@include buttonWidth;
// 		}
// 	}

// 	.social-reviews-body {
// 		max-width: 400px;
// 		margin: 40px auto;

// 		@include tablet {
// 			max-width: 49em;
// 		}
// 	}

// 	.social-reviews-logo {
// 		background: $white;
// 		padding: 20px 30px;
// 		width: 142px;
// 		margin: 0 auto;
// 		position: absolute;
// 		left: 0;
// 		right: 0;
// 		top: -30px;
// 		@include tablet {
// 			width: 212px;
// 			top: -52px;
// 		}
// 	}

// }

// /////  Reviewtracker Override

// .social-reviews .amplify-widget span,
// .social-reviews .amplify-widget div {
// 	font-family: 'Source Sans Pro', sans-serif;
// }

// div[ data-test-id="card" ] {
// 	border-color: $neutral-dk;
// 	border-radius: 7px;
// }

// div[ data-test-id="review-content" ]{
// 	@extend %body-2;
// }

// div[ data-test-id="author" ]{
// 	@extend %body-1;
// }

// .amplify-widget {

// 	.fa-chevron-left,
// 	.fa-chevron-right {
// 		color: $neutral-dk;
// 	}

// 	.fa-circle {
// 		border: solid 2px;
// 		border-color: $neutral-dk;
// 		border-radius: 50%;
// 		margin: 0 .25em;

// 		path {
// 			fill: transparent;
// 		}

// 	}

// 	svg[ color="#8D95A3" ]{
// 		background: $neutral-dk;
// 	}
	
// 	.gbFcHI {
// 		color: $secondary;
// 	}

// }

