.flash-message {
	background-color: $secondary;
	color: $secondary-invert;
	padding: 12px;

	 
	@include desktop {
		padding: 30px 0;
	}

	.flash-message-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;  
	}

	.flash-message-close {
		@extend .modal-slide-close;
		top: 0;
		right: 0;
		border-color: $secondary-invert;
		background-color: transparent;
		
		@include until( $tablet ){
			width: 18px;
			height: 18px;
		}

		&::before, &::after {
			background-color: $secondary-invert;

			@include until( $tablet ){
				width: 12px;
				left: 2px;
			}
		}
		
		&:hover {
			background-color: $secondary-invert;
			&::before, &::after {
				background-color: $secondary;
			}
		}

		@include tablet {
			top: 50%;
			transform: translateY( -50% );
		}
	}

	.flash-message-content {
		text-align: center;
		font-weight: 700;
		text-transform: uppercase;
		color: $secondary-invert;
		margin: 0 40px;

		p, a, h2, h3, h4, h5 {
			color: $secondary-invert;
			margin-bottom: 0;
			line-height: 1.5;
		}

		p {
			 @extend %subhead;
			 font-size: 22px;
		}

		a {
			text-transform: none;
			@extend %link;
			font-size: 22px;
			color: $secondary-invert;
				&:hover {
					color: $secondary-invert;
					text-shadow: -1px 1px 1px rgba( 0, 0, 0, .15), 
					1px 1px 1px rgba( 0, 0, 0, .15), 
					1px -1px 1px rgba( 0, 0, 0, .15), 
					-1px -1px 1px rgba( 0, 0, 0, .15);
					cursor: pointer;
				}
			
		//Someone is going to want this back
			// &[ href^="tel" ]{
			// 	&::after {
			// 		content: none;
			// 	}
			// }
		}

		@include until( $tablet ){
			margin: 0 30px;

			p, a {
				font-size: 12px;
			}
		}

	}

}