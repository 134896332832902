.slick-prev.slick-arrow::before {
	font-family:'pathway-icons';
	content: '\e875';
}
.slick-next.slick-arrow::before {
	font-family:'pathway-icons';
	content: '\e876';
}
.slick-prev {
	left: 20px;
}
.slick-next {
	right: 20px;
}
.slick-dots {
	margin-top: 30px;
}
.slick-dots li button:before {
	color: $white;
	font-size: 24px; 
	font-family: 'pathway-icons';
	content: '\f10c';
}
.slick-dots li.slick-active button:before {
	font-family: 'pathway-icons';
	content: '\f111';
	color:white;
}
