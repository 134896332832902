.promo-grid {
    padding: 60px 0 14px;
    margin-bottom: 0;
    background: $white;
    background-image: linear-gradient($white 50%, $primary-color-lighter 50%);
    @include tablet {
        padding: 94px 0 112px;
    }

    .promo-grid-section-title{
        @extend %h1;
        color: $neutral-dk;
        margin-bottom: 48px;
        @include tablet {
            margin-bottom: 106px;
        }
    }

    .promo-grid-item {
        @extend %theme-card;
        border-width: 4px;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
        @include tablet {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    h3.promo-grid-title {
        @extend %subhead;
        color: $neutral-dk;
        margin-bottom: 34px;
        @include until($tablet){
            font-size: 20px;
        }
        @include tablet {
            margin-bottom: 46px;
        }
    }

    h4.promo-grid-offer {
        @extend %offer;
        line-height: 1;
        margin-bottom: 25px;
        @include tablet {
            margin-bottom: 50px;
        }
    }

	.promo-grid-description,
	.promo-grid-description p{
        @extend %body-2;
        margin-bottom: 24px;
        max-width: 28em;
        @include tablet {
            margin-bottom: 54px;
        }
    }

    .promo-grid-link {
		@extend %button-link;
        text-decoration: none;
        margin: 0 auto;
        width: 90%;
        @media (min-width: 320px){
            width: 232px;
        }
    }

    .slick-arrow {
        display: none!important;
    }
    .slick-dots{
        bottom: -60px;
        li button::before {
            border: none;
        }
    }
}

// .promo-grid {
//     background: $primary-color;


//       .promo-grid-section-title {
//           color: $white;
//           text-align: center;
//       }

//       .promo-grid-item {
//           background: $white;
//       }

//       .promo-grid-offer {
//           border: none;
//           font-size: 36px;
//           @include until($tablet){
//             font-size: 29px;
//           }
//       }

//       .promo-grid-title, .promo-grid-offer {
//           margin-bottom: 50px;
//       }

//       .promo-grid-offer {
//           color: $secondary;
//       }

//       .slick-dots li.slick-active button::before {
//           color: $white;
//           background: $white;
//       }


//       .slick-dots li button::before {
//           color: $white;
//           border-color: $white;
//       }


// }