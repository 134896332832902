.modal-slide-close {
	border-radius: 30px;
	&::before, &::after {
		width: 18px;
		left: 5px;
	}
}

.slick-slider {
	.slick-dots {
		bottom: -80px;
		left: 0;
		li {
			&:active,
			:focus,
			::-moz-focus-inner {
				border: 0;
			}
			button {
				&::before {
					content: "";
					box-sizing: content-box;
					width: 14px;
					height: 14px;
					border-radius: 14px;
					background-color: $neutral-dk;
					opacity: 1;
				}
			}
			&.slick-active {
				top: -1px;
				button::before {
					background-color: $white;
					border-style: solid;
					border-width: 2px;
					border-color: $neutral-dk;
					opacity: 1;
					top: -1px;
					left: -1px;
				}
			}
		}
	}

	.slick-arrow {
		z-index: 100;
		position: absolute;
		bottom: -80px;
		&.slick-next, &.slick-prev{
			transform: none;
			top: auto;
			width: 40px;
			height: 40px;
			color: $neutral-dk;
			&:hover {
				color: $secondary;
			}
		}
		&.slick-next::before {
			right: 0;
		}
		&.slick-prev::before {
			left: 0;
		}
		&::before, ::after {
			position: absolute;
			top: 0;
			font-size: 40px;
			color: inherit;
		}  
	}

}