.two-column-promo {

	.two-column-promo-container {
		@include maximize-width;
	}

	.columns {
		@extend .is-gapless;
	}

	&.two-column-promo-with-image {
		.two-column-promo-col {
			background-color: $primary-color-lighter; 

			@include tablet {
				padding: 40px!important;
			}
		}

	}

	.two-column-promo-image {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;  

		@include desktop {
			min-height: 550px;
		}

		img {
			flex: 1 1 auto;

			@include tablet {
				position: absolute;
				object-fit: cover;
				object-position: 50% 0;
				@include object-fit( cover, 50% 0 );
				width: 100%;
				height: 100%;
				
			}
		}
	}

	.half-column-container {
		text-align: center;
		padding: 56px 20px 80px;
		margin: 20px 32px 32px 32px;
		background-color: $white;
		border-radius: 10px;
		@media( min-width: 400px) {
			width: 380px;
			margin-left: auto;
			margin-right: auto;
		}
		@include tablet {
			width: 100%;
			max-width: none;
			padding: 20px 40px;
			margin: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			// max-width keeps the container squarish
			// max-width: 560px;
			margin-right: auto;
			margin-left: auto;

			p.half-column-content{
				margin: 40px 0 40px!important;
			}
		}
		@include widescreen {
			padding: 105px 50px 98px;
		}
		.title {
			@extend %h1;
			text-transform: none;
			color: $neutral-dk;
			margin-bottom: 43px;
			@include tablet {
				margin-bottom: 50px;
			}
		}
	}

	.half-column-content {
		margin-bottom: 43px;
		@include tablet {
			max-width: 24em;
			margin-bottom: 50px;
		}
	}

	.half-column-cta {
		@extend %button-link;
		@include buttonWidth;
		min-width: 200px;
	}

	.image-right.two-column-promo-image{
		order: 2;
	}

}
