.module-contact {
    margin-bottom: 0;
    background-color: $primary-color-light;
    padding: 37px 26px 92px;
    @include tablet {
        padding-top: 81px;
        padding-bottom: 56px;
    }
    @include desktop {
        padding-left: 0;
        padding-right: 0;
    }

    .container {
        max-width: $desktop;
    }

    .contact-notice {
        @extend %body-1;
        max-width: 38em;
        text-align: center;
        margin: 0 auto 56px auto;
        @include tablet {
            margin-bottom: 78px;
        }
    }

    form {
        padding: 0;
        .columns {
            margin-bottom: 50px;
            @extend .is-variable, .is-8-widescreen;
        }
    }

    .contact-field {
        margin-bottom: 8px;
        @include tablet {
            margin-bottom: 40px;
        }
    }

    .contact-button {
        margin: 0 auto;
        width: 100%;
        max-width: 306px;
        display: block;
        margin-bottom: 50px;
    }

    .contact-form-message {
        @extend %body-1;
        text-align: center;
    }
    

    .contact-image { 
        display: none!important;
    }

}

// .module-contact {

//     $form-height: 680px;
    
//     .contact-notice, form {
//         @include mobileSidePadding;
//         padding-top: 60px;
//         padding-bottom: 60px;
//     }

//     .contact-notice, .contact-control-submit, .contact-form-message {
//         text-align: center;
//     } 

//     .contact-notice {
//         max-width: 45em;
//         color: $neutral-dk;
//         margin-left: auto;
//         margin-right: auto;
//     }

//     .contact-group {
//         position: relative;
//         width: 100%;
//         max-width: 1600px;
//         margin: 0 auto;
//     }
 
//     form {
//         @extend %brand-border-top;
//         border-top-width: 20px;
//         background: $primary-color;
//         &, label {
//             color: $white;
//         }
//         ::placeholder {
//             color: $white;
//         }
//         @media (min-width:$tablet){
//             padding: 30px 45px;
//         }
//     }

//     input, textarea {
//         background: $primary-color;
//         color: $white;

//         border-color: $white;
//     }

//     .contact-image {
//         img {
//             width: 100%;
//             height: auto;
//         }
//     }

//     .button.module-button {
//         border-radius: 0;
//     }

//     @media (min-width: $desktop) {
//         .contact-group {
//             min-height: $form-height;
//             display: flex;
//             align-items: center;
//         }
//         form {
//             height: $form-height;
//             max-width: 630px;
//             position: absolute;
//             right: 0;
//             top: 50%;
//             transform: translateY(-50%);
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//         }

//         .contact-image {
//             width: 75%;
//             height: 100%;
//             position: relative;
//             z-index: -10;
//         }
        
//         .module-field-submit {
//             margin-bottom: 0;
//         }
//     }
// }

 