.one-col-info {
	text-align: center;
	border: none;
	@include backgroundSvg;
	background-size: 286px 156px;
	background-repeat: repeat-x;
	background-position: bottom left;
	padding: 70px 0 110px 0; 
	position: relative;
	
	@include tablet {
		background: none!important;
		background-color: $white;
		padding-top: 186px;

		&::after {
			content: '';
			@include backgroundSvg;
			width: 100%;
			height: 304px;
			position: absolute;
			left: 0;  
			top: 50%;
			transform: translateY(-50%);
			z-index: 0;
			background-position: top left;
			background-repeat: repeat; 
			background-size: 366px 200px;
		}
	}

	.one-col-info-container {
		padding-bottom: 48px;   
		
		@include tablet {
			padding-left: 40px;
			padding-right: 40px;
    
		}
	}

	.one-col-info-content {
		padding-top: 88px;
		background-color: $white;
		border: solid 4px;
		border-color: $neutral-dk;
		border-radius: 8px;
		position: relative;
		max-width: 820px;
		margin-left: auto;
		margin-right: auto;
		z-index: 10;

		@include until($tablet){
		   border: none;
		   &::before {
			   content: "";
			   position: absolute;
			   width: 200%;
			   left: -50%;
			   top: 0;
			   height: 4px;
			   background-color: $neutral-dk;
		   }
		}

		@include tablet {
			padding: 120px 83px 70px;
		}

	}

	.one-col-info-icon {
		font-size: 80px;
		width: 80px;
		margin: 0 auto;
		position: absolute;
		left: 0;
		right: 0;
		top: -46px;
		border-left-style: solid;
		border-left-width: 4px;
		border-left-color: $white;
		background-color: white;
		box-sizing: content-box;
		@include bg-dot;
		z-index: 10;
		
		.p-icon {
			position: relative;
			z-index: 5;
		}

		&::after {
			z-index: 0;
			top: -8px;
		}
	}

	.one-col-info-title,
	.one-col-info-body {
		@include mobileSidePadding;
	}

	.one-col-info-title {
		@extend %h1;
		margin-bottom: 32px;

		@include tablet {
			margin-bottom: 62px;
		}
	}

	.one-col-info-body {
		max-width: 38em;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 32px;

		@include tablet {
			max-width: 46em;
		}
	}

	.one-col-info-cta {
		@extend %button-primary;
		@include buttonWidth;
		margin-right: 2em;

		&:last-child {
			margin-right: auto;
		}
	}
}

.one-col-info.with-bg .one-col-info-container {
	@extend .container;
}

.one-col-info.with-bg,
.one-col-info .with-bg {
	background: $primary-color-light;
	background-image: none !important;
	mask: none!important;
	@include mobileSidePadding;
	padding-bottom: 70px;

	@include tablet {
		padding-bottom: 100px;
	
	}
	
	&::after {
		background-image: none !important;
		mask: none!important;
		background: transparent;
	}

	.one-col-info-icon {
		display: none;
	}

	.one-col-info-content {
		border: solid 2px;
		border-color: $neutral-dk;
		padding-top: 44px;
		padding-bottom: 32px;
		&::before {
			content: none;
		}

		@include tablet {
			max-width: 940px;
			padding-top: 98px;
			padding-bottom: 62px;
		}
	}

	.one-col-info-body {
		@include tablet {
			margin-bottom: 60px;
		}
	}

	.one-col-info-cta {
		@extend %button-link;
	}
}

.one-col-info.photo-upload {
	@extend .with-bg;
}
