.three-col-contact {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	@include until($desktop){
		padding-left: 5%;
		padding-right: 5%;
	}

	@include tablet {
		padding-top: 124px;
		padding-bottom: 44px;
	}

	.three-col-contact-section-title {
		@extend %h1;
		margin-bottom: 1em;

		& + .three-col-contact-container {
			margin-top: 40px;
			@include tablet {
				margin-top: 70px;
			}
		}
	}

	.three-col-contact-banner {
		@extend %body-1;
		max-width: 48em;
		margin: 0 auto 4em;
		padding: 0 $side-padding;
	}

	.three-col-contact-title {
		margin-bottom: 20px;
	}

	.three-col-contact-description {
		margin-bottom: 44px;
		p {
			@extend %body-2;
			margin-bottom: 1em;
		}
	}

	.three-col-contact-container {
		margin-bottom: 44px;
	}
	
	.three-col-contact-disclaimer {
		@extend %body-1;
		max-width: 54em;
		padding-left: $side-padding;
		padding-right: $side-padding;
		margin-left: auto;
		margin-right: auto;
	}
}
