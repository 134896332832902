.blog-featured {
	padding-bottom: 42px;

	@include tablet {
		padding-bottom: 62px;
	}

	.blog-featured-image-wrap {
		max-height: 680px;
	}
	
	.blog-featured-caption {
		background: none;
		@include mobileSidePadding;
	}

	.blog-featured-title {
		@extend %h1;
		max-width: 48em;
		padding-left: $side-padding;
		padding-right: $side-padding;
		margin-left: auto;
		margin-right: auto;

		a {
			@extend %link;
			font-size: inherit;
			color: $neutral-dk;

			&::after {
				top: 0;
				display: inline;
				letter-spacing: 0;
				margin: 0;
				padding: 0;
				content: "\00a0\e876";
			}

			&:hover {
				color: $secondary;
			}
		}
	}

	.blog-featured-date {
		@extend %body-1;
	}

	a.blog-featured-link {
		display: none;
	}
}