section.error {
	background: $primary-color-light;

	.error-image {
		position: relative;
		z-index: 1;
		svg {
			position: relative;
			z-index: 5;
				path {
				fill: $neutral-dk;
			}
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 90%;
			height: 90%;
			border-radius: 50%;
			background: $primary-color-lighter;
			right: -10%;
			top: -10%;
		}


	}

	.error-cta {
		width: 80%; 
		
		@include tablet {
			width: auto;
			min-width: 310px;
		}
	}
 
} 