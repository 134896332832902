.app-display {

  @include tablet {

    .app-display-title {
		margin-bottom: 46px;
		
		@include tablet {
			font-size: 45px;
		}
    }

    .app-display-content {
        margin-bottom: 92px;
    }

  }

  .app-display-body {
	p {
		@extend %body-2;
		margin-bottom: 1em;
	}
  }

}
