.module-forms {

	$border-color: rgb(180, 180, 180);

	.form-intro-container {
		@include mobileSidePadding;
		padding-top: 80px;

		@include tablet {
			padding-top: 120px;
			margin-bottom: 60px;
		}
	}

	.form-wrapper {
		@include mobileSidePadding;
		background-color: $primary-color-light;
		padding-top: 40px;
		padding-bottom: 80px;

		@include tablet {
			padding-top: 100px;
			padding-bottom: 120px;
		}
	}

	.form__divider.column {
		margin-top: 40px;
		margin-bottom: 6px;
		margin-left: auto;
		margin-right: auto;
		
		h2 {
			text-align: left;
		}

		@include tablet {
			margin-top: 75px;
			margin-bottom: 78px;

			h2 {
				text-align: center;
			}
		}

		&:first-child {
			margin-top: 40px;
		}
	}

	label.label {
		@extend %body-1;
		color: $black;
		margin-bottom: 4px;
		margin-left: 7px;
	}

	input, 
	textarea, 
	select, 
	.select::before,
	.datetimepicker-dummy-wrapper {
		min-height: 46px;
        border-color: $border-color;
        border-width: 1px;
		border-radius: 7px;
		
		@include tablet {
			min-height: 70px;
		}
	}
	
	.datetimepicker-dummy {
		@include tablet {
			min-height: 70px;
		}
	}

    .label {
        @extend %body-1;
    }

    form {
        padding: 0;
        .columns {
            margin-bottom: 50px;
            @extend .is-variable, .is-8-widescreen;
        }
    }

    .field {
        margin-bottom: 8px;
        @include tablet {
            margin-bottom: 40px;
        }
    }

	.control > .button {
		@extend %button-secondary;
		width: 100%;

		@include tablet {
			width: auto;
			min-width: 240px;
			height: 56px;
		}
	} 

	.control {
		&.clone, &.delete {
			.button {
				padding-right: 20px;
				&::after {
					content: none;
				}
			}
		}
	}
	
	.form__submitbtn {
		.control {
			text-align: center;
			.button {
				@extend %button-primary;

				@include tablet {
					min-width: 320px;
					height: 56px;
				}
			}
		}
	}

	.form__checkboxterms {
		text-align: center;

		p {
			text-align: left;
		}
	}

	.checkbox {
		align-items: center;
		margin-top: 1em;
		.help {
			@extend %body-2;
			color: $black;
			margin-bottom: 0;
		}
	}

	.style-checkbox {
		border-color: $neutral-dk;
		border-width: 3px;
		border-radius: 4px;
		width: 26px;
		height: 26px;

	}

	// .checkbox input[ type= "checkbox" ]:checked + .style-checkbox {
	// 	&::after {
	// 		top: 5px;
	// 		left: 5px;
	// 	}
	// }

	.forms-required-message {
		margin-top: 3em;
	}

	@include tablet {
		.select, .datetimepicker-dummy-wrapper {
			.errormessage {
				position: absolute;
				top: 60px;
			}
		}
	} 

	 .checkbox .errormessage {
		padding: 0;
		top: 30px;
	}

	.datepicker_input {
		background: $white;
		padding-left: 4rem;
	}

	.form__datepicker {
		.control {
			&::before {
				left: 18px;
			}
		}
	}


	@include until( $tablet ){
		.form-intro-container {
			margin-bottom: 40px;
		}

		.form__divider.form-accordion-header {
			background: $primary-color;
			margin: 10px -#{$side-padding};
			padding-left: $side-padding;
			padding-right: $side-padding;

			&:first-child {
				margin-top: -40px;
			}

			h2 {
				color: $white;
				padding-right: 20px;
				// font-size: 30px; 
			}
		}

	}
	
	.filepond--panel-root {
		background: #fff;
		opacity: .5;
		border: solid 1px;
		border-color: darken($border-color, 50%);
	}

	 .filepond--item-panel{
		background: $primary-color;
	}

}
