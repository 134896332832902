.header {
	padding-top: 24px;

	@include desktop {
		padding-top: 38px;
		padding-bottom: 24px;
	}

	.navbar {
		@include desktop {
			align-items: flex-end;
		}
	}

	.navbar-cta {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 100;
		position: relative;

		@include until( $desktop ){
			display: none;
		}
	}

	.main-logo {
		padding: 0;

	}

	.navbar-brand {
		@include mobileSidePadding;
		@include until( $desktop ){
			margin-bottom: 20px;
		}
	}

	.navbar-container .navbar .navbar-menu {
		align-items: center;
		box-shadow: none;
		background: $neutral-dk;
		padding-top: 30px;

		@include desktop {
			background: $white;
			padding-top: 0;
		}
	}

	.menu-item {
		@include until( $desktop ){
			display: block;
			margin: 0 auto 15px;

			& .menu-link {
				display: flex;
				justify-content: space-between;
				align-items: center;

				& .p-icon {
					flex: 0 1 50%;
					text-align: right;
					height: auto;
					padding: 0;
					display: inline-block;
					position: relative;
					top: -2px;
				}
			}
		}

		&.navbar-item {
			padding: 0;
		}

		@include desktop {
			margin-right: 14px;

			& .p-icon {
				position: relative;
				top: -2px;
				padding-left: 8px;

			}
		}
	}

	.menu-item.has-dropdown {
		.p-icon::before {
			font-size: 80%;
			display: inline-block;
			transform: rotate( -90deg );
			transition: transform .3s;
		}

		&.sfHover {
			.p-icon::before {
				transform: none;
			}
		}
	}

	.menu-item.menu-item.has-dropdown {
		// transition: background .25s;
	}

	.menu-item.sfHover {
		@include until( $desktop ){
			// transition: background 0s;
			background: $white;
			// border-top: solid 1px;
			// border-color: $black;
		}
	}
	
	.menu-link,
	.dropdown-link {
		@include mobileSidePadding;
		color: $black;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;

		@include desktop {
			max-width: none;
		}
	}

	.dropdown-link {
		font-weight: 400;

		&:hover {
			color: $black;
			background: $primary-color-light;
		}
	}

	.menu-link {
		@extend %button-text;
		font-size: 25px;
		text-transform: uppercase;
		padding-top: 16px;

		@include until( $desktop ){
			border: none;
			height: 40px;
			color: $white;
		}

		@include desktop {
			font-size: 16px;
			padding: 12px 10px;
		}
	}

	.menu-item.sfHover .menu-link {
		@include until( $desktop ) {
			color: $neutral-dk;
			z-index: 10;
			position: relative;
		}
	}

	.menu-item.sfHover .menu-link,
	.menu-link:hover {
		@include desktop {
			background: $primary-color-light;
			color: $black;
		}
	}


	.navbar-link.menu-link.is-current,
	.dropdown-item.is-current .dropdown-link {
		position: relative;
		color: $secondary;
	}

	.navbar-dropdown {
		@extend %button-text;
		background: $white;
		box-shadow: none;
		border-radius: 0;
		border: none;

		@include until( $desktop ){
			border-bottom: solid 1px;
			border-color: $black;
		}

		@include desktop {
			margin-top: 18px;
			border: solid 2px;
			border-color: $neutral-dk;
			border-radius: 4px;
		}
	}

	.dropdown-item {
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 0px;
		padding-right: 0;
	}

	.dropdown-link {
		display: block;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;

		@include desktop {
			padding-left: 18px;
			padding-right: 26px;
		}
	}

	.online-pharmacy-mobile {
		@include desktop {
			display: none;
		}
	}

	.appointment-cta-1 {
		display: none;
	}

	.appointment-cta {
		text-align: center;
	}

	.requestAppt-cta {
		@extend %button-primary;
		font-size: 18px;
		margin: 44px auto;

		@include desktop {
			font-size: 16px;
			color: $secondary;
			background: $white;
			border-color: $secondary;
			margin: 0 0 0 30px;

			&:hover {
				color: $secondary-invert;
				background: $secondary;
				border-color: $secondary;
			}
		}
	}

	.online-pharmacy-desktop a {
		@extend %link;
		display: block;
		text-decoration: none;
		background: $primary-color;
		color: $primary-color-invert;
		padding: 12px 60px 12px 40px;
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 35px;

		&:hover {
			color: $primary-color-invert;
			background: $primary-color-darker;
		}
	}

	.online-pharmacy-mobile  {
		text-align: center;
		margin-bottom: 50px;
		a {
			@extend %button-text;
			font-weight: 400;
			font-size: 20px;
			color: $white;

			&:hover {
				color: $secondary;
			}
		}
	}

}

.fixed-header {
	@include desktop {
		.header .navbar-cta {
			transform-origin: 100% 0;
			top: -10px;
		}

		.requestAppt-cta {
			padding: 4px 16px;
		}

		.online-pharmacy-desktop .pharma-link {
			padding: 8px 60px 8px 20px;
			margin-bottom: 0;
		}

		.navbar-menu {
			margin-bottom: -8px;
		}

		.header .navbar-dropdown {
			margin-top: 0;
		}

		.navbar-container .navbar .navbar-menu {
			background: transparent;
		}

	}
}
