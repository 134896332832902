$family-primary: 'Source Sans Pro', sans-serif;

body {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400; 
}

h2, h3, h4, h5, h6, p {
  color: $text;
}

h1, .h1, %h1 {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 25px;
  letter-spacing: .01em;
  line-height: 1.07;
  margin-bottom: 10px;
  color: $neutral-dk;
  @include tablet {
	font-size: 45px;
  }
}

.hero-1, %hero-1 {
  @extend %h1;
  font-size: 36px;
  @include tablet {
	font-size: 66px;
  }
  @include desktop {
	font-size: 95px;
  }
}

.hero-2, %hero-2 {
  @extend %h1;
  line-height: 1; 
  font-size: 32px;

  @include tablet {
	font-size: 45px;
  }

  @include desktop {
	font-size: 65px;
  }
}

h2, .h2, %h2 {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 25px;
  letter-spacing: .06em;
  line-height: 1;
  margin-bottom: .3em;
  color: $neutral-dk;
  margin-bottom: .33em;

  @include tablet {
	font-size: 30px;
  }
}

h3, .h3, .subhead, %h3, %subhead  {
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 25px;
  letter-spacing: .08em;
  line-height: 1.2em;
  margin-bottom: 1em;
}

p, .p, .body-1, %p, %body-1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: .1em;
  line-height: 1.5;
  @include tablet {
	font-size: 20px;
  }
}

.body-2, %body-2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: .06em;
  margin-bottom: 2em;
  @include tablet {
	font-size: 16px;
  }
}

.footer-text, %footer-text {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: .06em;
	line-height: 1.5;
	margin-bottom: 10px;
}

.button-text, %button-text {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 700;
	letter-spacing: .06em;
}

.button, %button-extend {
	@extend %button-text;
	border-radius: 1.5em;
	z-index: 1;

	&::after {
		content: "\e876";
		font-family: 'pathway-icons';
		font-size: 60%;
		padding-left: .5em;
		text-decoration: none;
		display: inline-block;
	}
}

.button-primary, 
a.button-primary, 
.button.is-primary, 
%button-primary {
	@extend %button-extend;
	position: relative;
	color: $primary-color-invert;
	background: $primary-color;
	border-color: $primary-color;

	a {
		color: $primary-color-invert;
		text-decoration: none !important;
	}
	
	&:hover, :active {
		background-color: $primary-color-darker;
		border-color: $primary-color-darker;
	}

	.base-content & {
		padding-top: 8px;
		padding-bottom: 8px;
		border-width: 2px;
	}

}

.button-secondary, 
a.button-secondary, 
%button-secondary {
	@extend %button-extend;
	border-width: 2px;
	padding-top: 8px;
	padding-bottom: 8px;
	background: $white;
	color: $secondary;
	border-color: $secondary;
	position: relative;
	z-index: 1;

	a {
		color: $secondary;
		text-decoration: none !important;
	}

	&:hover {
		color: $secondary-invert;
		border-color: $secondary;
		background: $secondary;
		a {
			color: $secondary-invert;
		}
	}



}

%button-link {
	@extend %button-extend;
	position: relative;
	color: $secondary-invert;
	background: $secondary;
	border-color: $secondary;

	a {
		color: $secondary-invert;
	}
	
	&:hover, :active {
		background-color: $secondary-darker;
		border-color: $secondary-darker;
	}

}

.link, %link {
	text-decoration: none;
	border: none;
	font-size: 16px;
	letter-spacing: .1em;
	line-height: 1.2;
	font-weight: 700;
	border-bottom: solid 1px;
	border-color: currentColor;

	&::after {
		content: "\2002\e876";
		font-family: 'pathway-icons';
		font-size: 60%;
		text-decoration: none;
		display: inline;
		position: relative;
	}
	
	@include tablet {
	  font-size: 20px;
	}
  }

  .offer, %offer {
	@extend %subhead;
	color: $secondary;
	font-size: 50px;
	letter-spacing: .08em;
}
 
  h1.page-title {
	text-align: center!important;
}

.module-shim {
	background-color: $primary-color-light;
}

.generic-content { 
	padding-top: 80px;
	padding-bottom: 80px;
}

.base-content {

	h1 {
		margin-bottom: 1rem;
	}

	h4 {
		@extend %body-1;
		text-transform: uppercase;
	}

	h2, h3, h4 {
		margin-bottom: .75rem;
	}

	p { 
		margin-bottom: 2.5rem;
    }
    
    li {
        @extend %body-1;
    }

	blockquote {
		margin-top: 5em;
		margin-bottom: 5em;

		&::before {
			content: "\201c\2009";
		}

		&::before, p {
			font-family: 'Playfair Display', serif;
			font-size: 20px;
			color: $neutral-dk;
			display: inline;

			@include tablet {
				font-size: 30px;
			}
		}
	}

}

.page-blocks {
	margin-top: 40px;

	@include tablet {
		margin-top: 80px;
	}
}