.footer-marketing {
  &.modal {
    z-index: 1000;
  }

  .modal-background {
    opacity: .6;
  }

  .footer-marketing-container {
    background: $white;
    padding: 70px 30px;
    position: relative;
    border-radius: 4px;
    &::before {
      content: "";
      position: absolute;
      width: calc( 100% - 40px );
      height: calc( 100% - 40px );
      border: solid 4px;
      border-color: $neutral-dk;
      border-radius: 4px;
      left: 20px;
      top: 20px;
    }
  }

  .modal-slide-close {
    right: 34px;
    top: 34px;
  }

  .three-col-img-image {
    margin-bottom: 30px;
    z-index: 10;

    .p-icon {
      z-index: 0;
    }
  }

  a.button {
    text-decoration: none;
  }

}
