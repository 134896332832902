.module-blog-grid {
	background-color: $primary-color-light;
	margin-bottom: 0;
	padding: 40px 0;

	@include tablet {
		padding-top: 112px;
		padding-bottom: 154px;
	}

   .blog-grid-title {
		@extend %h1;
		text-align: center;
		padding-left: $side-padding;
		padding-left: $side-padding;
		margin-left: auto;
		margin-right: auto;
		max-width: 48em;
		margin-bottom: 38px;
		@include tablet {
			margin-bottom: 104px;
		}
	}

	.blog-grid-card {
		// padding-top: 0;
		.blog-grid-card-image {
			border: none;
		}
		.blog-grid-card-content {
			border: none;
		}
	}

	.blog-grid-block-link {
		display: flex;
		height: 100%;
	}

	.blog-grid-card-content {
		padding-top: 34px;
		padding-bottom: 34px;
		text-align: left;

		.blog-grid-date {
			@extend %body-2;
			color: $black;
		}
	
		.blog-grid-post-title {
			@extend %subhead;
			color: $neutral-dk;
			margin-bottom: 44px;
		}
	}

	.blog-grid-link {
		margin-bottom: 0;
		color: $secondary;

		&:hover {
			color: $neutral-dk;
		}

	}

	.blog-grid-card-image.blog-card-no-hero {
		width: 100%;
		background: $primary-color-lighter;
	}

}

