.affiliate-logos {
	@extend .is-variable;
	@extend .is-8-widescreen;
}

.three-col-carousel {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 55px 0 216px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		@include backgroundSvg;
		background-repeat: repeat-x;
		background-size: 294px 162px;
		background-position: bottom left;
	}

	@include tablet {
		padding: 113px 0 275px;
	}

	@include desktop {
		padding-top: 172px;
	}
	
	.three-col-carousel-container {
		width: 100%;
	}

	h2.three-col-carousel-title{
		@extend h1;
		text-align: center;
		color: $neutral-dk;
		text-transform: none;
		margin-bottom: 60px;
		@include desktop {
			margin-bottom: 110px;
		}
	
	}
	
	.affiliate-logo {
		width: 50vw;
		height: auto;		
		margin-left: auto;
		margin-right: auto;
	
		@include tablet {
			width: 20vw;
			height: auto;
		}
	}
	
	.three-col-carousel-description {
		margin-top: -3em;
		margin-bottom: 4em;
	}

}
