.two-col-with-photo {

	.two-col-photo-container {
		@include maximize-width;
	}

	.two-col-photo-columns {
		@extend .is-gapless;
	}

	.two-col-photo-col.two-col-photo-col-text.column {
		background-color: $primary-color-lighter;
		color: $primary-color-invert; 
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include tablet {
			padding: 40px!important;
			text-align: center;
			.half-column-title {
				text-align: center;
			}
		}
	}

	.two-col-photo-col-image {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;  

		@include desktop {
			min-height: 550px;
		}

		img {
			flex: 1 1 auto;

			@include tablet {
				position: absolute;
				object-fit: cover;
				object-position: 50% 0;
				@include object-fit( cover, 50% 0 );
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				
			}
		}
	}

	.half-column-container {
		text-align: center;
		padding: 56px 20px 56px;
		margin: 20px 32px 32px 32px;
		background-color: $white;
		border-radius: 10px;
		max-width: 550px;
		width: 100%;

		@media( min-width: 400px) {
			width: 380px;
			margin-left: auto;
			margin-right: auto;
		}

		@include tablet {
			width: 100%;
			max-width: none;
			padding: 40px 50px;
			margin: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			// max-width keeps the container squarish
			// max-width: 560px;
			margin-right: auto;
			margin-left: auto;
		}

		@include widescreen {
			padding: 105px 50px 98px;
		}

		.half-column-title {
			@extend %h1;
			text-transform: none;
			color: $neutral-dk;
			margin-bottom: 20px;

			@include desktop {
				margin-bottom: 43px;
			}
		}
	}

	.half-column-content {
		margin-bottom: 0px;
		width: 100%;
		@include tablet {
			max-width: 24em;
			// margin-bottom: 50px;
		}
	}

	.half-column-cta {
		@extend %button-link;
		@include buttonWidth;
		min-width: 200px;
		margin-top: 42px;

		@include desktop {
			margin-top: 52px;
		}
	}
	 
}

.image-right.two-col-with-photo {

	@include tablet {

		.two-col-photo-col-image {
			order: 2!important;
		}
	}

}
