.blog-info {
	@extend .is-12;
}
.blog-content {
	@extend .is-12;
}

.module-blog {
	margin-bottom: 80px;

	.container:first-child {
		@include maximize-width;
	}

	.blog-hero-image {
		border: none;
		// @include maximize-width;
		max-height: 680px;
		overflow: hidden;
	}
	.hero-caption {
		@include tablet {
			margin-left: 30px;
		}
	}
	.blog-article {
		@include until($tablet){
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	.blog-info {
		@include tablet {
			text-align: center;
			margin-bottom: 52px;
		}
	}
	h1.blog-title {
		text-transform: none;
		@include until($tablet){
			font-size: 30px;
		}
		@include tablet {
			margin-bottom: 32px;
		}
	}
	.blog-byline {
		margin-bottom: 1.9em;
		@include tablet {
			margin-bottom: 1em;
		}
	}
	.blog-date {
		color: $black;
	}
	.blog-social {
		margin-bottom: .75em;
		@include tablet {
			justify-content: center;
		}
	}
	.blog-social-title {
		@extend %button-text;
		text-transform: uppercase;
		color: $secondary;
		&::after {
			content: ":";
			margin-left: -.25em;
		}
	}
	.blog-social-link a{
		color: $neutral-dk;
	}

	.blog-content {
		text-align: left;

		p {
			line-height: 2.14;
			margin-bottom: 4.4em;

			img {
				margin-top: 10px;
			}
		}

		@include desktop {
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.blog-no-hero {
		.blog-title {
			margin-top: 60px;

			@include tablet {
				margin-top: 100px;
			}
		}
	}
}
